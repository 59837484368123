import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import url from 'url'

import config from '../../../utils/siteConfig'

// 2022-04-29 remove helmet
import { Helmet } from 'react-helmet'

// 2022-04-29 pulling out potential meta query from ghost
//import ArticleMeta from './ArticleMeta'


import WebsiteMeta from './WebsiteMeta'

// 2022-04-29 pulling out potential meta query from ghost
//import AuthorMeta from './AuthorMeta'

/**
* MetaData will generate all relevant meta data information incl.
* JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
*
*/



const MetaData = ({
    data,
    settings,
    title,
    description,
    image,
    location,
}) => {
    // console.log("Metadata location.pathname", location.pathname);
    
    
    const canonical = url.resolve(config.siteUrl, location.pathname)
   

    //const { ghostPost, ghostTag, ghostAuthor, ghostPage } = data
    // settings = settings.allGhostSettings.edges[0].node

    // if (ghostPost) {
    //     return (
    //         <ArticleMeta
    //             data={ghostPost}
    //             canonical={canonical}
    //         />
    //     )
    // } else if (ghostTag) {
    //     return (
    //         <WebsiteMeta
    //             data={ghostTag}
    //             canonical={canonical}
    //             type="Series"
    //         />
    //     )
    // } else if (ghostAuthor) {
    //     return (
    //         <AuthorMeta
    //             data={ghostAuthor}
    //             canonical={canonical}
    //         />
    //     )
    // } else if (ghostPage) {
    //     return (
    //         <WebsiteMeta
    //             data={ghostPage}
    //             canonical={canonical}
    //             type="WebSite"
    //         />
    //     )
    // } else {

    // RESTORE
    title = title || config.siteTitleMeta || settings.title
    
    description = description || config.siteDescriptionMeta || settings.description
    // console.log("Meta canonical: ", description);
    image = "";
    //image = image || settings.cover_image || null
    //image = image ? url.resolve(config.siteUrl, image) : null

    // if (!image)
    // {
    //     console.log("no image");
    // }
        // 

    // return (
    // <Helmet>
    //     <title>REMOVE THIS</title>
    // </Helmet>
    // );

    return (
        <WebsiteMeta
            data={{}}
            canonical={canonical}
            title={title}
            description={description}
            image={image}
            type="WebSite"
        />
    )
    
}

MetaData.defaultProps = {
    data: {},
}

MetaData.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.object,
        ghostTag: PropTypes.object,
        ghostAuthor: PropTypes.object,
        ghostPage: PropTypes.object,
    }),
    settings: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
}


// 2022-04-29 Creating a blank metadata query
const MetaDataQuery = props => (
    <MetaData {...props} />
)

export default MetaDataQuery

/**
 * 2022-04-29 No matter what - we can't have the metadata query
 

const MetaDataQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettingsMetaData {
                allGhostSettings {
                    edges {
                        node {
                            title
                            description
                        }
                    }
                }
            }
        `}
        render={data => <MetaData settings={data} {...props} />}
    />
)

export default MetaDataQuery

*/