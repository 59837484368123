import React from 'react'

import tw, { theme, GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

// format timestamp
import { formatDate } from "../../utils/time/DateFormat"
import { Link } from 'gatsby'

import '../../../styles/typography.css'

import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage, GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

declare interface cardProps {
    className?: any
    children?: any
    post: {
        title: string
        description: string
        author: string
        authorImageUrl: string
        author_picture_description: string
        reading_time: number
        created_at: string
        feature_image: string
        feature_image_description: string
        slug: string
    }
    image: any
}

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`



const cardTopStyle = css`
    .cardtop {
        height: 30rem;
    }

`

const cardFooterStyle = css`
    .cardfooter {
        height: 4rem;
    }

`

const authorFaceStyle = css`
.authorface {
    height: 41px;
    width: 41px;
    /* transform: scale(.2); */
}
`

const titleFontStyle = css`
h3 {
    font-family: "NewsGothic", sans-serif;
    /* line-height: 1.15; */
}

.description {
    font-family: "Xpress", sans-serif;
}
`


const composedStyle = css`
    ${tailwindStyle};
    ${titleFontStyle};
    ${authorFaceStyle};
`;




const BlogCard: React.FunctionComponent<cardProps> = ({ children, className, post, image}) => {

    const pathnameWithoutImagePrefix = new URL(post.feature_image).pathname.substr(8)

    // console.log("---------- post.feature_image", pathnameWithoutImagePrefix)
    // const imageURL = "../../../" + post.feature_image
    // console.log("---------imageuURL", imageURL)
    


    const renderImage = image.node.childImageSharp.gatsbyImageData

    let jsxImage

    if (image !== undefined) {

        jsxImage = <GatsbyImage
                        tw="w-full"
                        image={renderImage}
                        alt={post.feature_image_description}
                    />
    }
    else {
        jsxImage = <></>
    }


    const URLPathname = post.slug
    const modifiedURL = "/" + URLPathname


    return (
        <>
            {/* GlobalStyles does NOT cascade */}
            <GlobalStyles />
            <div className={composedStyle}>
                <Link
                    className="cardlink" 
                    tw="flex flex-col rounded-md shadow-md lg:mb-16 no-underline bg-bookmark-white h-full" 
                    to={modifiedURL}
                >

                    <div className="cardtop" tw="p-6 flex flex-col ">
                        {jsxImage}
                        {/* There is still the problem where the text makes things ultra small */}
                        <h3 tw="mt-5 mb-2 text-elegant-dk-brown text-2xl">{post.title}</h3>
                        <p className="description" tw="mb-2 text-elegant-brown font-light" >{post.description}</p>
                    </div>
                    <hr tw="border-b border-bookmark-white"/>
                    <div className="cardfooter" tw="flex flex-col px-6">

                        <div className="dateline" tw="flex flex-row justify-between pb-4">
                            <span tw="justify-start text-elegant-black">{formatDate(post.created_at)}</span>
                            <span tw="text-elegant-black justify-end">{post.reading_time.toString() + " minute read"}</span>
                        </div>

                        <div 
                            className="author"
                            tw="pt-2"
                        >
                            <StaticImage 
                                className="authorface"
                                tw="rounded-full  mb-2 mr-6"
                                src="../../../images/authors/white-owl-education/white-owl-education-author-face.jpg"
                                alt={post.author_picture_description}
                            />
                            <span tw="text-bookmark-grey font-light items-center flex flex-1">{post.author}</span>
                        </div>

                    </div>

                </Link>
            </div>
        </>
    );
}

export default BlogCard