import React from 'react'
import styled from "styled-components";

import tw, { theme, GlobalStyles } from "twin.macro"
import { css } from '@emotion/css'

import BlogCard from "./BlogCard"

// Query for card information
import { graphql, useStaticQuery } from 'gatsby'



declare interface cardProps {
    className?: any
    children?: any
    posts?: any
    images?: any
}

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

`

const Container = styled.div`
  ${tw`container`}

`

const titleFontStyle = css`
h2 {
    /* font-family: "Xpress", sans-serif; */
    /* line-height: 1.15; */
}


`


const composedStyle = css`
    ${tailwindStyle};
    ${titleFontStyle};
`;

function getImageNode(feature_image_partial_pathname: string, images: any)
{


    const searchRegExp = new RegExp(feature_image_partial_pathname);
    const matchingNode = images.filter(function(arrayItem){

        return searchRegExp.test(arrayItem.node.absolutePath)
    })


    // Filter json array to only return the node where partial_pathname is a regex match against absolute path

    return matchingNode;
}


const BlogCards: React.FunctionComponent<cardProps> = ({ children, className, posts, images }) => {



    // query BlogAPI to return 3 most recent non-featured blogs
    // staticQuery requires you to know the info at the time of the build
    
    

    var sortedPosts = posts.sort(function(a,b) {
        return new Date(b.node.created_at) - new Date(a.node.created_at)
    })

    return (
        <>
            <div className={tailwindStyle}>
                <GlobalStyles />
                <div className={composedStyle}>
                <section tw="py-20  bg-elegant-white">
                        {/* <!-- Heading --> */}
                        <div tw="sm:w-3/4 lg:w-5/12 mx-auto px-2">
                            <h2 tw="text-3xl text-center text-elegant-green">Recent Blogs</h2>
                        </div>
                        {/* cards */}

                        <div tw="container grid grid-cols-1 md:grid-cols-2  gap-16
                                max-w-screen-lg mt-16"
                                     
                        >
                            {/* this is the feed of posts */}
                            {sortedPosts.map(({ node }) => {
                                
                                const imageNode = getImageNode(node.feature_image_partial_pathname, images)
                                // The tag below includes the markup for each post - components/common/PostCard.js

                                let imageJson;
                                imageJson = imageNode[0];

                                // only show the blog if it is not featured
                                // console.log("NODE PROPS", node)
                                // && node.visibility != "private"
                                if(node.blog_featured === "no" && node.visibility === "public") {
                                return (
                                    <BlogCard key={node.id} post={node} image={imageJson} />
                                )
                                }
                                else {
                                    return (<></>)
                                }
                            })}
                            
                        </div>
                </section>
                </div>
            </div>
        </>
    );
}


export default BlogCards;

