import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { FeaturedPost } from '../components/common/promo/FeaturedPost'

import { Banner } from '../components/common/promo/Banner'
import BlogCards from '../components/styledcomponents/blog/BlogCards'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/

// console.log("Index: before starting index");

const Index = ({ data, location, pageContext }) => {
   
    
    const myQuery = graphql`
        query myQuery {
          gateway {
            edges {
              node {
                id
                slug
                updated_at
                created_at
                feature_image
                feature_image_partial_pathname
                feature_image_description
                title
                author
                description
                author_picture
                author_picture_description
                reading_time
                blog_featured
                visibility
               
              }
            }
          }
          images:allFile(filter: { absolutePath: {regex: "/static/images/blog/"}}) {
            edges {
              node {
                name
                absolutePath
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED,
                        layout: CONSTRAINED,
                        width: 500
                        formats: [AUTO, WEBP, AVIF]
                      )
                   }
              }
            }
          }
        }
      `
    const mydata = useStaticQuery(myQuery)
    const posts = mydata.gateway.edges
    const images = mydata.images.edges

    // console.log("Index: location", location);

    return (
        <>
            
            <MetaData location={location} /> 
            <Layout
                className="indexlayout"
                isHome={true}
                pageInfo={{
                  "title": "White Owl Education", 
                  "description": "White Owl Education teaches people cutting edge tech. Our newest course can teach you how to create a next-generation data visualization on a 3D globe.", 
          }}
            >
                {/* Disabling featured post to confirm this is contentful paint problem */}
                <FeaturedPost className="featured-post"/>
                {/* <Banner /> */}
                {/* replicate the blog cards here */}
                <BlogCards posts={posts} images={images}/>

            </Layout> 
            
           
        </>
    )
}

Index.propTypes = {
  data: PropTypes.shape({
      allGhostPost: PropTypes.object.isRequired,
  }),
  location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
}


// Index.propTypes = {
//     data: PropTypes.shape({
//         allGhostPost: PropTypes.object.isRequired,
//     }).isRequired,
//     location: PropTypes.shape({
//         pathname: PropTypes.string.isRequired,
//     }).isRequired,
//     pageContext: PropTypes.object,
// }

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination

// export const pageQuery = graphql`


// `;




// export const pageQuery = graphql`
//   query GhostPostQuery($limit: Int!, $skip: Int!) {
//     allGhostPost(
//         sort: { order: DESC, fields: [published_at] },
//         limit: $limit,
//         skip: $skip
//     ) {
//       edges {
//         node {
//           ...GhostPostFields
//         }
//       }
//     }
//   }
// `


// the goal is to find out how the gateway accepts parameters

// export const pageQuery = graphql`
//     query myQuery {
//         gateway (limit: 2) {       # This is the "allGhostPost"
//             edges {
//                 node {
//                     title
//                 }
//             }
//         }
//     }

// `