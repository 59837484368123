import React from 'react'
import { Link } from 'gatsby'

import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

// import * as process from 'process'

// import { Proces} from 'webpack'

// TODO The background image should not be hard coded
// requires src/types/images.d.ts
import backgroundImage from '../../../images/blog/2022/11/15/internal-card-main-image-small.webp'

// The style is not optional. In order for the style to work, className must be passed. 
// The classname has to be passed to a prop

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;


const featuredStyle = css`
.displayflex {
    display: inline-flex;
    flex-direction:column;
}

.purpbutton {
    margin-top:20px;
    width: 175px;
}


.bg-cover {
    
    background-size: cover;
}

.bg-center {
    background-position: center;
}

.h-auto {
    height: auto;
}

.text-black {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    text-shadow: 1px 1px 2px black;
}


.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
   
        
    
}

.py-4 {
    padding: 1rem;
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-24 {
    padding-top: 12rem;
    padding-bottom: 12rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.object-fill{
    object-fit: fill;
}


.font-bold {
    font-weight: 900;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.uppercase {
    text-transform: uppercase;
}

.text-2x1 {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-3x1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.mb-10 {
    margin-bottom: 1.5rem;
}

.leading-none {
    line-height: 1;
}

.bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}

@media (min-width: 768px) {
    .md-w-1-2 {
        width: 50%;
    }
}

.rounded {
    border-radius: 0.25rem;
}

a {
    text-decoration: none !important;
}

a :hover {
        --tw-bg-opacity: 1;
        background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
        
    }

a.hover-text-gray-800 :hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.frostedglass {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.01rem;
    /* background: hsl(213 14% 10% / 90%); */
    background: #423b82ba;
    backdrop-filter: blur(5px);
    border-radius: 0.25rem;
    width:280px;
}

.frostedglass p {
    margin-top: unset !important;
}

l-100 {
    left:-100px;
    top:-100px;
}

.overlay {
    left:68%;
}

`

const composedStyle = css`
    ${tailwindStyle};
    ${featuredStyle};
`;


export const FeaturedPost: React.FC<{className: string}> = ({ className, children }) => {

    const tagline = "Tutorial";
    const headline = "Learn BigQuery";
    const description = "Increase your chances of getting a data analyst job with BigQuery while examining election contributions.";

    return (
        <>
         <div className={composedStyle}>
            <GlobalStyles />
            <div tw="container">
                <div tw="pt-12 pb-10 px-10 md:pb-36 inline-block relative">
                    <div tw=" ">
                        <img tw=""
                            src={ backgroundImage }
                            alt={"Political donations examined with Google Cloud BigQuery"}
                            width={1200}
                            height={630}
                        />
                        
                        {/* <div tw="hidden sm:right-12 sm:block z-20 md:w-5/6 md:-mt-96 sm:mr-10 mb-20 sm: mt-4" className=""></div> */}
                        {/* Display on the right for medium or larger */}
                        <div tw="hidden md:block  relative z-20 sm:-mt-96" className="overlay">
                            <div tw="text-white mb-10" className="frostedglass">
                                <p className="font-bold text-sm uppercase">Tutorial</p>
                                <p tw="text-3xl font-bold">Learn BigQuery</p>
                                <p className="text-2xl mb-10 pt-10 leading-none">{description}</p>
                            </div>
                            <Link to="/blog/2022/11/15/tutorial-bigquery/"
                                tw="py-4 px-8 text-white font-bold uppercase bg-yellow-600 opacity-90 rounded hover:bg-gray-200 hover:text-gray-800 mt-20 w-40" 
                                >
                                Start learning!
                            </Link>
                            
                        </div>
                    </div>
                    {/* display below main pic for small */}
                    <div tw="z-20 mt-4 sm:hidden" className="">
                        <div tw="text-white mb-10" className="frostedglass">
                            <p className="font-bold text-sm uppercase">{tagline}</p>
                            <p tw="text-3xl font-bold">{headline}</p>
                            <p className="text-2xl mb-10 pt-10 pb-4 leading-none">{description}</p>
                        </div>
                        <Link to="/blog/2022/11/15/tutorial-bigquery/"
                            tw="py-4 px-8 text-white font-bold uppercase bg-yellow-600 rounded hover:bg-gray-200 hover:text-gray-800 mt-20 w-40" 
                            >
                            Start learning!
                        </Link>
                        
                    </div>

                    <div tw="z-20 mt-4 hidden md:hidden sm:block" className="">
                        <div tw="text-white mb-10" className="frostedglass">
                            <p className="font-bold text-sm uppercase">{tagline}</p>
                            <p tw="text-3xl font-bold">{headline}</p>
                            <p className="text-2xl mb-10 pt-10 pb-4 leading-none">{description}</p>
                        </div>
                        <Link to="/blog/2022/11/15/tutorial-bigquery/"
                            tw="py-4 px-8 text-white font-bold uppercase bg-yellow-600 rounded hover:bg-gray-200 hover:text-gray-800 mt-20 w-40" 
                            >
                            Start learning!
                        </Link>
                        
                    </div>

                      
                </div>
            </div>
         </div>
        </>
    );

}

